import React, { Component } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

export default class FacebookLiveChat extends Component {
  render() {
    return (
      <div>
        <MessengerCustomerChat
          pageId="116119796894367"
          appId="129540310984709"
        />
      </div>
    );
  }
}
