import React, { Component } from "react";

class Resume extends Component {
  getRandomColor() {
    const color_palette = ["#311b92","#1a237e","#0d47a1","#01579b","#512da8","#303f9f","#1976d2","#0288d1","#673ab7","#3f51b5","#2196f3","#03a9f4"]
    const color = color_palette[Math.floor(Math.random()*color_palette.length)]
    return color;
  }

  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return (
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
            </p>
            <div>
              {education.description.map((description) => {
                return (
                  <ul>
                    <li>{description}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        );
      });
      var work = this.props.data.work.map(function (work) {
        return (
          <div key={work.company}>
            <h3>{work.company}</h3>
            <p className="info">
              {work.title}
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <div>
              {work.description.map((description) => {
                return (
                  <ul>
                    <li>{description}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        );
      });

      var skills = this.props.data.skills.map((skills) => {
        var className = "bar-expand " + skills.name.toLowerCase();
        var src = "./images/techstacks/" + skills.image
        return (
          <img className='skill-logo' src={src} alt={skills.name}/>
        );
      });
    }
    return (
      <section id="resume">
         <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">{work}</div>
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{education}</div>
            </div>
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{skillmessage}</p>

            <div className="bars">
              <ul className="skills">{skills}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
