import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Button = styled.button`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #0288d1;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
`;

const FloatingMascot = (props) => {
  const { linkAnchor } = props;
  const ButtonEl = useRef(null);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      ButtonEl.current.style.display = "block";
    } else {
      ButtonEl.current.style.display = "none";
    }
  }

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 60);
    }
  };

  return (
    <Button ref={ButtonEl} onClick={scrollToTop}>
      Go Up
    </Button>
  );
};

export default FloatingMascot;
