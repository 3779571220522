import React, { Component } from "react";
import Slider from "./NetflixSlider"
class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = "images/portfolio/" + projects.image;

        return (
          <div key={projects.title} className="columns portfolio-item">
            <div className="item-wrap">
              <a href={projects.url} title={projects.title}>
                <img alt={projects.title} src={projectImage} />
                <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{projects.title}</h5>
                    <p>{projects.category}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        );
      });
    }

    const movies = [
      {
        id: 4,
        image: '/images/portfolio/GSB_NPA.png',
        imageBg: '/images/portfolio/GSB_NPA.webp',
        title: 'NPL NPA',
        description: '\n NPL NPA is the project for GSB (known as Government Saving Bank of Thailand). Its purpose is to serve as web application for internal usage among intranet of GSB. \n It is not exaggerating to say that this project is a big innovation that changes GSB NPLA system from now on.'
      },
      {
        id: 3,
        image: '/images/portfolio/bicannabis.png',
        imageBg: '/images/portfolio/bicannabis.webp',
        title: 'BI Cannabis',
        description: 'BI cannabis project is the project work with FDA of Thailand, in purpose of researching possibility to control and monitor cannabis businesses in Thailand. It is the prototype project built using HTML5, AdminLTE, ChartJS and Anychart technology stacks. '
      },
      {
        id:2,
        image:'/images/portfolio/posenet_example.png',
        imageBg: '/images/portfolio/HEIG-VD-2018-Featured-image.webp',
        title: 'Internship at HEIG-VD, Switzerland',
        description:'Working on the project entitled “Deep Learning for object recognition in videos” under supervision of Professor A. Perez-Uribe. Integrating camera with PoseNet to make real-time pose estimation and to understand the concept of Neural Network. He is also studying on how to use PoseNet to perform skeleton detection and obtain skeleton values to understand pose patterns.'
      },
      {
        id:1,
        image:'/images/portfolio/iRobotCreate2018.jpg',
        imageBg: '/images/portfolio/iRobot_image.webp',
        title: 'iRobot Create 2018 Competition',
        description:'Participated in iRobot Create competition 2019 (The Next Chapter of Smart Living) and qualified for the final round'
      },
      {
        id:0,
        image:'/images/portfolio/50th-Featured.jpg',
        imageBg: '/images/portfolio/abac_exhibition.webp',
        title: 'Assumption University’s 50th Anniversary Grand Celebration',
        description:'Represent Engineering Faculty in university’s exhibitions.'
      }
    ];

    return (
      <section id="portfolio">
        <div className="row">
            <h1>Portfolio</h1>
              <Slider>
                {movies.map((movie) => (
                  <Slider.Item movie={movie} key={movie.id}>
                    item1
                  </Slider.Item>
                ))}
              </Slider>
        </div>
      </section>
    );
  }
}

export default Portfolio;
